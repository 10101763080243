/*
.boxed {
  border: 1px solid cornflowerblue; 
}

*/
:root {
  --header-height-photo: 645px;
  --header-toolbar-offset: 25px;
  --header-album-title-text: 70px;
}
.not-visible-mobile{
  visibility: visible;
}
.mobile-visible{
  visibility: collapse;
  height:0;
}
.breadcrums-no-mobile{
  margin-top: -20px;
  margin-right: 25px;
  padding: 40px;
  padding-bottom: 25px;
}
.header-layout{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top:100px;
}

.tab-buttons-mobile{
  display: flex;
  overflow: hidden;
  min-height: 48px;
  -webkit-overflow-scrolling: touch;
  margin-right: 0px;
  height: 70px
}
.blur-background{
  background-color: rgba(0, 0, 0, .5);
  backdrop-filter: blur(2px)  !important;
  border-radius: 25px;
}
.tab-buttons{
  margin-top: calc(var(--header-toolbar-offset) - 45px);
  margin-right: 20px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  padding-top: 50px;
  padding-bottom: 50px;

}
.alice-carousel__stage-item {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  max-height: 600px;
  vertical-align: middle;
  white-space: normal;
  line-height: 0;
}
.alice-carousel__slide-info {
  position: absolute;
  top: 0px;
  right: 8px;
  display: inline-block;
  padding: 5px 10px;
  color: #a6d4fa;
  border-radius: 5px;
  background-color: #303030;
  border: 1px solid rgba(166, 212, 250, 0.5);
}
.image-gallery-content:not(.fullscreen) .image-gallery-slide .image-gallery-image  {
  margin-top: 1px;
  max-height: calc(100vh - 260px);
}







@media only screen and (max-width: 1024px){
  .image-gallery-content:not(.fullscreen) .image-gallery-slide .image-gallery-image {
    max-height: 500px;
  }
}

@media only screen and (max-width: 900px){
  .image-gallery-content:not(.fullscreen) .image-gallery-slide .image-gallery-image {
    max-height: 450px;
  }
}
@media only screen and (max-width: 600px){
  .image-gallery-content:not(.fullscreen) .image-gallery-slide .image-gallery-image {
    max-height: 333px;
  }
}
@media only screen and (max-width: 450px){
  .image-gallery-content:not(.fullscreen) .image-gallery-slide .image-gallery-image {
    max-height: 200px;
  }
}

@media only screen and (orientation: landscape){
  .image-gallery-content:not(.fullscreen) .image-gallery-slide .image-gallery-image  {
    margin-top: 1px;
    max-height: calc(100vh - 130px);
  }
}

@media only screen and (max-width: 823px) and (orientation: landscape){
  .image-gallery-content:not(.fullscreen) .image-gallery-slide .image-gallery-image {
    max-height: 230px;
  }
} 
@media only screen and (max-width: 736px) and (orientation: landscape){
  .image-gallery-content:not(.fullscreen) .image-gallery-slide .image-gallery-image {
    max-height: 270px;
  }
} 
@media only screen and (max-width: 668px) and (orientation: landscape){
  .image-gallery-content:not(.fullscreen) .image-gallery-slide .image-gallery-image {
    max-height: 220px;
  }
} 
@media only screen and (max-width: 568px) and (orientation: landscape){
  .image-gallery-content:not(.fullscreen) .image-gallery-slide .image-gallery-image {
    max-height: 180px;
  }
}

.alice-carousel__prev-btn [data-area]::after, .alice-carousel__next-btn [data-area]::after {
  color: white;
}
.alice-carousel__prev-btn:hover [data-area]::after, .alice-carousel__next-btn:hover [data-area]::after {
  font-weight: 700;
  color: #a6d4fa;
  text-decoration: underline;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  /* width: 100%; */
  width: auto;
  line-height: 0;
  max-height: 67px;
}

.image-gallery {
  background: #303030;
}
html {
  display: block;
  color: internal-root-color-;
  background: #303030;
}
/*__________________AlbumGrid*/
.album-grid-body-container-no-display{
  margin: 2vh;
  padding: 0;
  /* margin-top: 4vh;
  margin-bottom: 4vh; */
} 
.album-grid-body-container-parent{
  display: flex;
  justify-content: center;
  align-items: center;
}
.album-grid-body-container{
    max-width: 850px;
    margin-bottom: 4vh;
    padding-top: 1vh;
    display: flex;
    justify-content: space-around;
    align-content: baseline;
    
}
.album-grid-body-container-about-parent-grandpa{
  display: flex;
  justify-content: center;
  align-items: center;
}
.album-grid-body-container-about-parent{
  padding-left: 50px;
  padding-right: 50px;
}
.album-grid-body-container-about{
  margin: 2vh;
  margin-top: 4vh;
  margin-bottom: 4vh;
  
}
.album-grid-body-container{
  display: grid;

  grid-template-columns: 5fr  5fr  5fr;
  grid-template-rows: 5fr;
  gap: 10px;
  grid-template-areas:
          " a  b  c ";

}
.album-grid-body{
    padding: 1vh;
    display: grid;

    grid-template-columns: 5fr  5fr   5fr;
    grid-template-rows: 5fr  5fr;
    gap: 10px;
    grid-template-areas:
            " a  b  c "
            " e ";

}
.album-grid-cell-a{
  grid-area: a;
}
.album-grid-cell-b{
  grid-area: b;
}
.album-grid-cell-c{
  grid-area: c;
}
.album-grid-cell-e{
  grid-area: e;
}
/* .album-grid-cell-f{
  grid-area: f;
}
.album-grid-cell-g{
  grid-area: g;
} */
/*.boxed {*/
/*  !* border: 1px solid teal; *!*/
/*}*/




/* xxsmall handsets portrait*/
@media only screen and (max-width: 480px)  {
  .album-grid-body{
    grid-template-columns: 5fr;
    grid-template-rows: 5fr 5fr 5fr 5fr;
    row-gap: 1fr;
    grid-template-areas:
            "a"
            "b"
            "c"
            "e"
            ;
  }
  .header-layout{
    margin-top:50px;
  }
  .album-grid-body-container-about-parent{
    padding-left: 20px;
    padding-right: 20px;
  }
}
/* xsmall 480 - 600 landscape small handsets*/
@media only screen and (min-width: 480px) and (max-width: 600px)  {
  .album-grid-body{
    grid-template-columns: 5fr;
    grid-template-rows: 5fr 5fr 5fr 5fr;
    grid-template-areas:
            "a"
            "b"
            "c"
            "e"
            ;
  }
  .header-layout{
    margin-top:50px;
  }
}
/*small 600 -960*/
@media only screen and (min-width: 600px) and (max-width: 960px)  {
  .album-grid-body{
    grid-template-columns: 5fr  5fr;
    grid-template-rows: 5fr 5fr;
    grid-template-areas:
            "a b"
            "c e";
  }
}
/*@media only screen and (orientation: landscape){*/
/*  .album-grid-body{*/
/*    min-height: 70vh;*/
/*    grid-template-columns: 5fr 0.5fr 5fr;*/
/*    grid-template-rows: 5fr 0.5fr 5fr 0.5fr 5fr;*/
/*    grid-template-areas:*/
/*            "a  .  b"*/
/*            ".  .  ."*/
/*            "c  .  e"*/
/*            ".  .  ."*/
/*            "f  .  g";*/
/*  }*/
/*}*/
/*medium 960 -1280*/
/*@media only screen and (min-width: 960px) and (max-width: 1280px)  {*/

/*}*/
/*large 1280 -1920*/
/*@media only screen and (min-width: 1280px) and (max-width: 1920px)  {*/

/*}*/
/*xlarge > 1920*/
/*@media only screen and (min-width: 1920px)  {*/

/*}*/
.grid-cell-boxed {
  border: 1px solid cornflowerblue;
}
.grid-gap{
  /*min-height: 50vh;*/
}
.grid-row-gap{
  /*min-height: 5vh;*/
}
h4{
    text-shadow: 0 1px 1px #000;
    line-height: 20px;
    word-break: break-word;
    margin: 0;
    font-size: 20px;
    /*line-height: 26px;*/
    font-weight: 400;
}
/*__________________AlbumGrid*/
.about-me-image{
    max-height: 500px;
}
.about-me-content-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
}
.about-me-container{
  display: grid;

  grid-template-columns: 1fr  4fr   1fr;
  grid-template-rows: 5fr;
  gap: 10px;
  grid-template-areas:
          " a  b  c ";
}
.about-me-img-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.about-me-text-container{
    margin-top: 40px;

    margin-bottom: -20px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 750px;
}

.social-media-icons-container{
    display: flex;
    justify-content: center;
    padding-top: 60px;
    padding-bottom: 50px;

}
.svg-twitter{
    background-color: rgb(28, 186, 223);
    border-radius: 20%;
    border-color: rgba(0, 0, 0, 0.25);
    border-style: solid;
    border-width: 5px;
    margin-left: 5px;
    margin-right: 5px;
}
.svg-linked-in{
    background-color: rgb(36, 92, 157);
    border-radius: 20%;
    border-color: rgba(0, 0, 0, 0.25);
    border-style: solid;
    border-width: 5px;
    margin-left: 5px;
    margin-right: 5px;
}
.svg-instagram{
    border-radius: 20%;
    border-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 1px;
    width:50px; height:50px;
    background: #f09433;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
    margin-left: 5px;
    margin-right: 5px;
}
.svg-facebook{
    background-color: rgb(58, 85, 159);
    border-radius: 20%;
    border-color: rgba(0, 0, 0, 0.25);
    border-style: solid;
    border-width: 5px;
    margin-left: 5px;
    margin-right: 5px;
}

.header-img {
  /* max-height: 200px;
  margin-top: -69px;
  overflow: hidden;
  padding: 0px;
  width: 100%; */
  /* height: 400px; */
  background-image: url('./photos/nicolaslarrea/header/header.jpg'); 
  /* background-color: #000000; */
  /* background-position: center top; */
  /* background-repeat: repeat; */

  /* background-repeat: repeat; */
  /* background-attachment: fixed; */
  background-size:cover;
  height:var(--header-height-photo);
  /* overflow-y: hidden; */
}
.header-img-portraits{
  background-image: url('./photos/nicolaslarrea/header/portraits.jpg');
  background-size:cover;
  height:var(--header-height-photo);
}
.header-img-arquitecture{
  background-image: url('./photos/nicolaslarrea/header/arquitecture.jpg'); 
  background-size:cover;
  height:var(--header-height-photo);
}
.header-img-street{
  background-image: url('./photos/nicolaslarrea/header/street.jpg');
  background-size:cover;
  height:var(--header-height-photo);
}
.header-img-landscapes{
  background-image: url('./photos/nicolaslarrea/header/landscapes.jpg');
  background-size:cover;
  height:var(--header-height-photo);
}
.header-img-about{
  background-image: url('./photos/nicolaslarrea/header/about.jpg');
  background-size:cover;
  height:var(--header-height-photo);
}
.header-img-albums{
  background-image: url('./photos/nicolaslarrea/header/albums.jpg');
  background-size:cover;
  height:var(--header-height-photo);
}
@media only screen and (max-width: 1280px)  {
  .album-grid-body-container-no-display{
    margin: 0vh;
    padding: 0vh;
    margin-top: 4vh;
    margin-left: 0vh;
    margin-right: 0vh;
    margin-bottom: 4vh;
  }
  .album-grid-body-container-about{
    margin: 0vh;
    padding: 0vh;
    padding-top: 0vh;
    margin-top: 4vh;
    margin-left: 1vh;
    margin-right: 1vh;
    margin-bottom: 4vh;
  }
  .album-grid-body-container{
    margin: 0vh;
    padding: 0vh;
    padding-top: 0vh;
    margin-top: 4vh;
    margin-left: 1vh;
    margin-right: 1vh;
    margin-bottom: 4vh;
  }

}
@media only screen and (max-width: 900px)  {
  .about-me-container{
    display: grid;
  
    grid-template-columns: 0.01fr  10fr   0.01fr;
    grid-template-rows: 5fr;
    gap: 10px;
    grid-template-areas:
            " a  b  c ";
  }
}
.album-header-layout{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top:var(--header-album-title-text);
}

.header-album{
  padding-top: 120px;
}
/*ms < 1280px lg devices > 1280px < 1920 extra large devices*/
/* @media only screen and (max-width: 1280px) { */
  @media only screen and (max-width: 1280px) {
  .album-header-layout{
    margin-top:30px;
  }
  .header-layout{
    margin-top:0px;
  }
  .header-album{
    padding-top: 70px;
  }
  .mobile-visible{
    visibility: visible;
    height:70;
  }
  .not-visible-mobile{
    visibility: collapse;
  }
    
  .header-img {
    background: none;
    height:410px;
  }
  .header-img-portraits{
    background: none;
    height:410px;
  }
  .header-img-arquitecture{
    background: none;
    height:410px;
  }
  .header-img-street{
    background: none;
    height:410px;
  }
  .header-img-landscapes{
    background: none;
    height:410px;
  }
  .header-img-about{
    background: none;
    height:410px;
  }
  .header-img-albums{
    background: none;
    height:410px;
  }
  .album-grid-body-container-about-parent-grandpa{
    margin-top: 4vh;
    margin-bottom: 4vh;
  }
}
/*xl extra large devises > 1920px*/
@media only screen and (min-width: 1921px) {
  .header-img {
    background: none;
    height:510px;
  }
  .header-layout{
    margin-top:35px;
  }
  .header-img-portraits{
    background: none;
    height:510px;
  }
  .header-img-arquitecture{
    background: none;
    height:510px;
  }
  .header-img-street{
    background: none;
    height:510px;
  }
  .header-img-landscapes{
    background: none;
    height:510px;
  }
  .header-img-about{
    background: none;
    height:510px;
  }
  .header-img-albums{
    background: none;
    height:510px;
  }
  .tab-buttons-mobile{
    margin-right: 0px;
    min-height: 0px !important;
    height:  0px !important;
  }
  .blur-background{
    background-color:#424242;/*elevation-1 back color*/
    border-radius: 25px;
  }
  .album-grid-body-container{
    margin-bottom: 2vh;
  }
  .album-grid-body-container-about-parent{
    padding-left: 180px;
    padding-right: 160px;
    margin-bottom: 2vh;
  }
}

/* xxsmall handsets portrait*/
@media only screen and (max-width: 480px)  {
  .about-me-image{
      max-height: 200px;
  }
  .about-me-text-container{
      max-width: 300px;
  }
  .header-layout{
    margin-top:50px;
  }
}
/* xsmall 480 - 600 landscape small handsets*/
@media only screen and (min-width: 480px) and (max-width: 600px) {
  .about-me-image{
      max-height: 300px;
  }
  .about-me-text-container{
      max-width: 450px;
  }
  .header-layout{
    margin-top:35px;
  }
}
/*small 600 -960*/
@media only screen and (min-width: 600px) and (max-width: 960px)  {
  .about-me-image{
      max-height: 350px;
  }
  .about-me-text-container{
      max-width: 530px;
  }
}
@media only screen and  (min-width: 1280px) and (max-width: 1920px)  {

  .mobile-visible{
    visibility: collapse;
    height:0;
    min-height: 0px;
  }
  .tab-buttons-mobile{
    margin-right: 0px;
    min-height: 0px !important;
    height:  0px !important;
  }
  .album-grid-body-container{
    margin-top: 1vh;
    margin-bottom: 2vh;
  }
  .album-grid-body-container-about-parent-grandpa{
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
}
.logo-text-header-layout{
  display: flex;
  flex-grow: 0;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  padding-top: -300px;
}
.logo-place{
  margin-left: 15px;
  margin-top: var(--header-toolbar-offset);
  position: sticky;
}

.logo-text-header-layout{
  margin-left: 5px;

}
.header-tit{
    text-shadow: 0 1px 1px #000;
    line-height: 20px;
    word-break: break-word;
    margin: 0;
    font-size: 20px;
    /*line-height: 26px;*/
    font-weight: 600;
    color: #dc2743;
}



